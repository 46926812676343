<!--
 * @Author: Neko
 * @Date: 2021-01-25 17:50:08
 * @LastEditTime: 2021-01-26 14:25:45
 * @LastEditors: Neko
-->
<template>
  <el-dialog :visible="isShowPaySuccessDialog" :title="$t('common.pay')" :close-on-click-modal="false" :show-close="false">
    <div class="dialog__success">
      <div class="dialog__qrcode">
        <div ref="qrcodeRef" />
      </div>

      <p class="dialog__tips">{{ $t('common.payTip') }}</p>

      <div class="dialog__buttons">
        <el-button class="button" type="primary" @click="onClickCancelPayBtn">{{ $t('common.waiverPayment') }}</el-button>
        <el-button class="button" type="primary" @click="onClickPaySuccessBtn">{{ $t('common.paySuccess') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ref, toRefs, computed, watch, reactive } from '@vue/composition-api'
import { MessageBox, Message } from 'element-ui'
import QRCode from 'qrcodejs2'

import { useRequest } from '@/utils/request.js'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    orderId: {
      type: [String, Number],
      default: ''
    },

    payType: {
      type: [String, Number],
      default: ''
    },

    price: {
      type: String,
      default: '0.00'
    }
  },

  emits: ['success', 'cancel'],

  setup(props, { root, emit }) {
    const $t = root.$jst
    const propsRef = toRefs(props)

    const qrcodeRef = ref(null)

    const data = reactive({
      isShowPaySuccessDialog: propsRef.visible.value,
      qrcode: null,

      isFirst: false
    })

    watch(() => propsRef.visible.value, res => {
      data.isShowPaySuccessDialog = res

      if (!res) return

      if (data.isFirst) return

      root.$nextTick(() => {
        if (qrcodeRef.value) {
          data.qrcode = new QRCode(qrcodeRef.value, {
            text: window.location.protocol + '//' + window.location.host + '/outter/pay?order_id=' + propsRef.orderId.value + '&payment_id=' + propsRef.payType.value + '&price=' + propsRef.price.value,
            width: 200,
            height: 200,
            colorDark: '#000',
            colorWhite: '#fff',
            correctLevel: QRCode.CorrectLevel.H
          })

          data.isFirst = true
        }
      })
    })

    const onClickCancelPayBtn = () => {
      MessageBox.confirm($t('common.payTip'), $t('cart.tips'))
        .then(() => {
          emit('cancel')
          // router.replace({ name: 'OrderDetail', query: { orderid: propsRef.orderId.value }})
        })
        .catch(() => {})
    }

    const { fetch: handlePaySuccessFetch, data: handlePaySuccessResult } = useRequest('order/handlePaymentSuccessAction', {
      data: computed(() => ({
        orderId: propsRef.orderId.value
      })),

      immediate: false,
      isCustom: true
    })

    watch(() => handlePaySuccessResult.value, res => {
      if (res.code === 200) {
        emit('success')
      } else {
        return Message.error($t('common.payErr'))
      }
    })

    const onClickPaySuccessBtn = () => {
      handlePaySuccessFetch()
    }

    return {
      ...toRefs(data),
      qrcodeRef,

      onClickCancelPayBtn,
      onClickPaySuccessBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog__success {
  .dialog__qrcode {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }

  .dialog__tips {
    margin-top: 10px;
    text-align: center;
    color: #999;
  }

  .dialog__buttons {
    // width: 150px;
    margin-top: 20px;
    text-align: center;

    .button {
      width: 150px;
    }
  }
}
</style>
