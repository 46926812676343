<template>
  <div class="form">
    <!--     https://www.paypal.com/cgi-bin/webscr   生产地址
         https://www.sandbox.paypal.com/cgi-bin/webscr   沙箱测试地址
-->
    <form id="sform" ref="formRef" :action="action" method="post" target="_blank">
      <input v-for="(value,key) in formData" :key="key" type="hidden" :name="key" :value="value">
    </form>
  </div>
</template>

<script>
import { onUpdated } from '@vue/composition-api'

export default {
  name: 'OrderForm',

  props: {
    formData: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    onUpdated(() => {
      const form = document.getElementById('sform')
      form.submit()
    })
  }
}
</script>

<style scoped lang='scss'>
.form{
  display: none;
}

</style>
